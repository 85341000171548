import {Action} from 'redux';

import {User} from "./UserTypes";

export interface Avatar {
  uuid?: string;
  file_name?: string;
  url?: string;
  mimetype?: string;
  width?: number;
  height?: number;
  size?: number;
  thumbnail?: Medium;
  medium?: Medium;
  date_modified?: string;
  date_added?: string;
}

export interface Medium {
  file_name?: string;
  url?: string;
}



export interface Supplier{
  uuid?: string;
  email?: string;
 address?:string;
 phonenumber?:string;
 delivery_time?:number;
 firstname?: string;
 lastname?: string;
}

export interface UpdateUserProps {
  firstname: string,
  lastname: string,
  phonenumber?: string,
  password?: string,
  role_uuid: string,
  user_uuid: string,
}


export interface TitleI18N {
  en?: string;
  fr?: string;
}


export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const UPDATE_PASSWORD_RESET = 'UPDATE_PASSWORD_RESET';
export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';

export const RECOVERY_PASSWORD_REQUEST = 'RECOVERY_PASSWORD_REQUEST';
export const RECOVERY_PASSWORD_SUCCESS = 'RECOVERY_PASSWORD_SUCCESS';
export const RECOVERY_PASSWORD_FAILURE = 'RECOVERY_PASSWORD_FAILURE';

export const UPDATE_USER_AVATAR_REQUEST = 'UPDATE_USER_AVATAR_REQUEST'
export const UPDATE_USER_AVATAR_SUCCESS = 'UPDATE_USER_AVATAR_SUCCESS'
export const UPDATE_USER_AVATAR_FAILURE = 'UPDATE_USER_AVATAR_FAILURE'



export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const LOGOUT = 'LOGOUT';

export interface LoadingAccountState {
  login_loading: boolean;
  login_failed: boolean;
  login_failed_message: string;

  update_profile_loading: boolean;
  update_profile_failed: boolean;
  update_profile_success: boolean;
  update_profile_failed_message: string;

  signup_loading: boolean;
  signup_success: boolean;
  signup_failed: boolean;
  signup_failed_message: string;

  avatar_loading:boolean;

  update_password_loading: boolean;
  update_password_success: boolean;
  update_password_failed: boolean;
  update_password_failed_message: string;
}

export interface AccountState {
  user?: User;
  token: string | undefined;
  loading: LoadingAccountState;
  all_features?: Feature[];
}

export interface Feature {
  id?: number;
  key?: string;
}

export interface LoginRequest extends Action {
  type: typeof LOGIN_REQUEST;
}

export interface LoginSuccess extends Action {
  type: typeof LOGIN_SUCCESS;
  token: string;
  user: User;
}

export interface LoginError extends Action {
  type: typeof LOGIN_FAILURE;
  message: string;
}

export interface  UpdateUserAvatarRequest extends Action{
  type:typeof UPDATE_USER_AVATAR_REQUEST
}

export interface UpdateUserAvatarSuccess extends Action {
  type:typeof UPDATE_USER_AVATAR_SUCCESS;
  user:User;
}

export interface UpdateUserAvatarFaillure extends  Action{
  type:typeof  UPDATE_USER_AVATAR_FAILURE;
  message:string;
}

export interface SignUpRequest extends Action {
  type: typeof SIGNUP_REQUEST;
}

export interface SignUpSuccess extends Action {
  type: typeof SIGNUP_SUCCESS;
  message: string;
}

export interface SignUpError extends Action {
  type: typeof SIGNUP_FAILURE;
  message: string;
}

export interface UpdatePasswordRequest extends Action {
  type: typeof UPDATE_PASSWORD_REQUEST;
}

export interface UpdatePasswordReset extends Action {
  type: typeof UPDATE_PASSWORD_RESET;
}

export interface UpdatePasswordSuccess extends Action {
  type: typeof UPDATE_PASSWORD_SUCCESS;
  message: string;
}

export interface UpdatePasswordError extends Action {
  type: typeof UPDATE_PASSWORD_FAILURE;
  message: string;
}

export interface Logout extends Action {
  type: typeof LOGOUT;
}
