import {
  Container,
  Box,
  AppBar,
  Theme,
  Hidden,
  IconButton,
  Typography,
  Button,
  Stack,
  Divider,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack';
import { ApplicationState } from 'store/types';
import { SearchComponent } from './component/searchComponent';
import { Iconify } from 'components';
import { APP_VALUES } from 'utils/contants';
import AppDropdownButton from 'components/AppDropdownButton';
import routes from 'routes/routes';
import AppModalForm from 'components/AppModalForm';
import { nieuw_project_fields } from './nieuw_project_fields';
import { useAuth0 } from "@auth0/auth0-react";
import config from 'config';

import {  useAccount, useMsal  } from '@azure/msal-react'
import { useAuth } from 'hooks/useAuth';
import { ls } from 'App'

interface DashboardHeaderProps {
  onMobileNavOpen: any;
  className?: string;
}

interface CustomButtonI {
  title: string,
  img: string,
  href: string
}

const useStyles = makeStyles((theme: Theme) => ({
  projectButton: {
    background: '#203864',
    minHeight: '34px',
    paddingTop: '6px',
    marginRight: '15px',
    paddingBottom: '6px',
    paddingLeft: '17px',
    paddingRight: '17px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  }

}));


const DashboardHeader = (props: DashboardHeaderProps) => {

  const { onMobileNavOpen, className, ...rest } = props;
  const { t, i18n } = useTranslation();

  const [showDrawer, setShowDrawer] = React.useState<boolean>(false);
  const [ user, setUser] = React.useState<any>(ls.get('user'));
  const [ isAdmin, setIsAdmin] = React.useState<boolean>(false);
  const [ profilePhoto, setProfilePhoto] = React.useState<any>(undefined);


  const projectModalRef = React.useRef(null)
  const history = useHistory();

  const { isAuthenticated, logout, getUserInfo} = useAuth();

  console.log(isAuthenticated());

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [search, setSearch] = React.useState("")
  const [isOpenProjectModal, setIsOpenProjectModal] = React.useState<boolean>(false);
  const [ authenticated, setAuthenticated] = React.useState<boolean| undefined>(undefined);
  const [adminMenu, setAdminMenu] = React.useState<{label: string, action: Function, icon: any} | undefined>(undefined);

  const { enqueueSnackbar } = useSnackbar();
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const openDrawer = (): void => {
    setShowDrawer(state => !state)
  }

  const closeDrawer = (): void => {
    setShowDrawer(state => !state)
  }

  const savePorject = (values: any) => {

  }

  useEffect(() => { setIsAdmin(user && user.roles && ((user.roles as string[]) ?? []).includes('Admin')); }, [user]);

  useEffect(() => {
    ( async () => {
      try {
          setUser(ls.get('user') ? ls.get('user') :  await getUserInfo());
      } catch (error) {
          console.table(error);
      }
      setAuthenticated(isAuthenticated());
    })();
  }, []);

  return (
    <AppBar color='transparent' className={className} elevation={1} {...rest} >

      {/* Nieuw project modal */}
      <AppModalForm
        handleClose={() => setIsOpenProjectModal(false)}
        title="Nieuw project"
        open={isOpenProjectModal}
        fields={nieuw_project_fields}
        formRef={projectModalRef}
        onSubmit={savePorject}
      />

      <Container maxWidth={APP_VALUES.containerMaxSize} sx={{ display: 'flex' }}>
        <Hidden mdUp>
          <IconButton sx={{ height: "32px", width: "32px", padding: 0, m: 0, mr: 1 }} onClick={openDrawer}>
            <Iconify icon="ci:hamburger" />
          </IconButton>
        </Hidden>
        {user && (<SearchComponent user={user} isAdmin={isAdmin} drawerOpen={showDrawer} closeDrawer={closeDrawer} />)}
        {/* <Box>
          <Hidden mdDown>
            <Button
              color='primary'
              variant='contained' sx={{ whiteSpace: "nowrap", height: "32px", minWidth: "unset!important" }}
              onClick={() => setIsOpenProjectModal(true)}
              startIcon={
                <Hidden smDown>
                  <Iconify icon="akar-icons:plus" />
                </Hidden>}
            >
              Nieuw project
            </Button>
          </Hidden>
          <Hidden mdUp>
            <Button
              color='primary'
              variant='contained' sx={{ whiteSpace: "nowrap", height: 32 }}
            >
              <Iconify icon="akar-icons:plus" height={24} width={24} />
            </Button>
          </Hidden>
        </Box> */}
        <Stack sx={{ position: "relative", ml: "10px", flexShrink: 0 }}>
          {/* <Select>
            <MenuItem>Test</MenuItem>
          </Select> */}
          <AppDropdownButton
            items={[
              {
                action: () => null,
                icon: (
                  <Box component={'section'}>
                    <Stack justifyContent={"center"} alignItems={"center"} sx={{ textAlign: "center" }} gap={"6px"}>
                      {user && user.profilePhoto &&(<img style={{ height: "96px", width: "96px", borderRadius: "999px", flexShrink: 0, objectFit: "cover" }} src={user.profilePhoto} />)}
                      {user && user.email && (<Typography variant="body1" fontWeight={600} noWrap sx={{ width: "200px" }}>{user.email}</Typography>)}
                      {user && user.roles && (<Typography fontSize={12} sx={{ color: "success.main", mb: "4px" }}>{((user.roles as string[] ?? [])).join(', ')}</Typography>)}
                      <Divider sx={{ color: "grey.500", width: "100%" }} />
                    </Stack>
                  </Box>
                )
              },
              ...(isAdmin ? [
                {
                  label: 'Admin instellingen',
                  action: () => null,
                  icon: <Iconify icon="ant-design:setting-outlined" />,
                },
                {
                  action: () => null,
                  icon: (<Divider sx={{ color: "grey.500", width: "200px" }} /> )
                }
              ] : []),
              {
                label: "Uitloggen",
                action: async () => { 
                  await logout()
                },
                icon: <Iconify icon="ic:round-exit-to-app" sx={{ transform: "rotate(180deg)" }} />
              },
            ]}
            color={"primary"}
            rounded
            width={"auto"}
            height={"30px"}
            icon={
              <Stack direction="row" alignItems={"center"} gap={"2px"} justifyContent={"flex-end"}>
                { user && user.profilePhoto && (<img style={{ height: "30px", width: "30px", borderRadius: "999px", flexShrink: 0, objectFit: "cover" }} src={user.profilePhoto} />)}
                <Box component={'section'} sx={{ color: "grey.300" }} >
                  <Iconify icon={"mi:caret-down"} height={20} width={20} />
                </Box>
              </Stack>
            }
          />
        </Stack>
      </Container>
    </AppBar>
  )
}


export default DashboardHeader
