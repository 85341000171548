import { Redirect } from 'react-router-dom';

import { useEffect, useState } from 'react';
import SplashScreen from 'components/SplashScreen';
import { msalConfig } from 'authConfig';
import { useAuth } from 'hooks/useAuth';
import { ls } from 'App'
import routes from './routes';

const AuthGuard = (props: any) => {
  const { children } = props;

    const { isAuthenticated, getUserInfo, isLoading} = useAuth();
    const [ authenticated, setAuthenticated] = useState<boolean | undefined>(isAuthenticated());
    const [ loading, setloading] = useState(isLoading());
    const [ noAccess, setNoAccess] = useState<number | undefined>(undefined);
    const erroStatusKey = "erroStatus";
    const userKey = "user";
  useEffect(() => {

    if (authenticated && !loading ) {
      (async () => {
        try {
          let data = ls.get(userKey);
          if (!data) {
          let user = await getUserInfo(msalConfig.loginRequest);
          let status = ls.get(erroStatusKey);
          if (status) {
            setNoAccess(status);
          }
          }
        } catch (error) {
          ls.remove(userKey);
        }
      })();
    }
  }, [authenticated, loading]);


  if (isLoading()) {
    return <SplashScreen/>;
  }

  if (noAccess) {
    if ([403].includes(noAccess)) {
      return <Redirect to={routes.errors.error_403} />;
    }
    else {
      return <Redirect to={routes.errors.error_404} />;
    }
  }
  if (!isAuthenticated()) {
    return <Redirect to={routes.auth.index} />;
  }

  return children;
}

export default AuthGuard;