import {Action} from 'redux';
import {Avatar, TitleI18N} from './AccountTypes';
import {Client, Group} from "./GlobalDataType";

export const LOAD_USERS_REQUEST = 'LOAD_USERS_REQUEST';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const LOAD_USERS_ERROR = 'LOAD_USERS_ERROR';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_REQUEST_ENDED = 'UPDATE_USER_REQUEST_ENDED';

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_REQUEST_ENDED = 'ADD_USER_REQUEST_ENDED';




export interface User {
  uuid?:          string;
  email?:         string;
  firstname?:     string;
  name?:     string;
  lastname?:      string;
  avatar?:        Avatar;
  site?:          UserSite;
  status?:        string;
  last_activity?: string;
  last_login?:    string;
  project_on?:number;
  project_done?:number;
  task_on?:number;
  task_done?:number;
  role?:          Role;
  date_added?:    string;
  date_modified?: string;
  added_by?: {
    uuid?: string
    firstname?: string
    lastname?: string
  }
}

export interface Role {
  name?: string;
  code?: string;
  uuid?: string;
}

export interface UserSite {
  name?:         string;
  localisation?: string;
  group_uuid?:   string;
  uuid?:         string;
  group?:        Group;
}


export interface SiteElement {
  uuid?:         string;
  name?:         string;
  localisation?: string;
}


export interface UserPagination {
  page?:        number;
  per_page?:    number;
  keyword?:     string;
  order_field?: string;
  mandant?: boolean;
  show_for_project_visibility?: boolean;
}


export interface UsersRange {
  page: number;
  per_page: number;
  keyword?: string;
  role_uuid?: string;
}


export interface LoadingUsersState {
  users: boolean;
}

export interface UserData {
  data: User[];
  total: number;
  pages: number;
    current_page: number;
}
export interface ClientData {
  data: Client[];
  total: number;
  pages: number;
  current_page: number;
}
export interface GroupData {
  data: Group[];
  total: number;
  pages: number;
  current_page: number;
}

export interface UsersState {
  loading: LoadingUsersState;
  data: UserData;
  range: UsersRange;
}

export interface LoadUsersRequest extends Action {
  type: typeof LOAD_USERS_REQUEST;
  range: UsersRange;
}

export interface LoadUsersSuccess extends Action {
  type: typeof LOAD_USERS_SUCCESS;
  data: UserData;
}

export const DELETE_USER = 'DELETE_USER';

export interface DeleteUserAction extends Action {
  type: typeof DELETE_USER,
  data: DeleteUserActionParams
}

export interface DeleteUserActionParams {
  uuid: string
}

export const CHANGE_USER_STATUS = 'CHANGE_USER_STATUS';

export interface ChangeUserStatusAction extends Action {
  type: typeof CHANGE_USER_STATUS,
  data: ChangeUserStatusActionParams
}

export interface ChangeUserStatusActionParams {
  // uuid: string,
  data: User
}

export interface LoadUsersError extends Action {
  type: typeof LOAD_USERS_ERROR;
}

export interface AddUserRequest extends Action {
  type: typeof ADD_USER_REQUEST;
}

export interface AddUserEnded extends Action {
  type: typeof ADD_USER_REQUEST_ENDED;
}

export interface UpdateUserRequest extends Action {
  type: typeof UPDATE_USER_REQUEST;
}

export interface UpdateUserRequestEnded extends Action {
  type: typeof UPDATE_USER_REQUEST_ENDED;
}

export const ADD_USER = 'ADD_USER';

export interface AddUserAction extends Action {
  type: typeof ADD_USER,
  data: AddUserActionParams
}

export interface AddUserActionParams {
  data: User
}

export const UPDATE_USER = 'UPDATE_USER';

export interface UpdateUserAction extends Action {
  type: typeof UPDATE_USER,
  data: User
}
