import { Button, Stack, Typography } from "@mui/material"
import React from "react"

interface Props {
  src?: string;
  title: string;
  description?: string;
  reload?: boolean;
}

const AppIllustration: React.FC<Props> = ({ src, title, description, reload }) => {

  return (
    <Stack justifyContent={"center"} alignItems={"center"}>
      <img style={{ height: "40vh", objectFit: "contain", opacity: 0.8 }} src={src} />
      <Typography variant="h5" sx={{ textAlign: "center", mt: 2 }}>{title}</Typography>
      <Typography variant="body1" sx={{ textAlign: "center", maxWidth: "450px" }}>{description}</Typography>
      {reload && (
        <Button color="primary" variant="contained" onClick={() => window.location.reload()} sx={{ mt: 2 }}>
          Herlaad de pagina
        </Button>
      )}
    </Stack>
  )

}

export default AppIllustration
