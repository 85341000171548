import { Box, Button, Stack, TextField, TextFieldProps, Typography } from '@mui/material'
import AppFileInput from 'components/AppFileInput';
import AppSelect, { AppSelectItem } from 'components/AppSelect';
import React, { useEffect, useState } from 'react'

type Props = TextFieldProps & {
  label?: string;
  unit?: string;
  select?: boolean;
  items?: AppSelectItem[];
  onValueChange?: (value: any) => void;
}

const AppTextField: React.FC<Props> = ({ label, type, unit, sx, select, items, ...otherProps }) => {

  const [file, setFile] = useState(null)
  useEffect(() => {
    if (file && type === "file") {
      if (otherProps.onValueChange) otherProps.onValueChange(file)
    }
  }, [file])


  return (
    <Stack direction={"column"}>
      {!select && (
        <Typography variant='body2' color={otherProps.error ? "error" : "grey.500"} sx={{ mb: "5px" }}>
          {label}
        </Typography>
      )}
      <Stack direction={"row"}>
        <Box component={'div'} flexGrow={1}>
          {select ? (
            <AppSelect
              label={label}
              items={(items || [])}
              {...otherProps}
            />
          )
            : type === "file" ? (
              <AppFileInput file={file} setFile={setFile} {...otherProps} />
            )
              : (
                <TextField
                  sx={{
                    ...(!unit ? {} : ({
                      "& .MuiInputBase-root": ({
                        borderRadius: "5px 0 0 5px !important",
                      })
                    })),
                    width: "100%",
                    backgroundColor: ((otherProps?.inputProps?.readOnly ?? false) === true ? '#FAFBFF': 'inherit'),
                    ...(sx || {}),
                  }}
                  {...otherProps}
                  onChange={(e) => {
                    if (otherProps.onValueChange) otherProps.onValueChange(e.target.value)
                  }}
                />
              )}
        </Box>
        {unit && (
          <Box component={'section'}>
            <Button tabIndex={-1} variant="contained" color='error' sx={{ height: "42px", borderRadius: "0 5px 5px 0 !important", boxShadow: "none", pointerEvents: "none" }}>
              <Typography variant='caption'>
                {unit}
              </Typography>
            </Button>
          </Box>
        )}
      </Stack>
    </Stack>
  )
}

export default AppTextField
