//my-app/src/authProvider.js
import { LogLevel } from "@azure/msal-browser";

// Msal Configurations
export const msalConfig = {
  publicApp: {
    auth: {
      authority: `${process.env.REACT_APP_AAD_AUTHENTICATION_ENDPOINT}/${process.env.REACT_APP_AAD_TENANT_ID}`,
      clientId: `${process.env.REACT_APP_AAD_CLIENT_ID}`,
      redirectUri: `/auth`
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true
    }
  },
  loginRequest: {
    scopes: [
      "User.Read",
      "AppRoleAssignment.ReadWrite.All"
    ]
  },
  apiRequest: {
    scopes: [
      `api://${process.env.REACT_APP_AAD_API_CLIENT_ID}/DefaultUser.Access`,
      `api://${process.env.REACT_APP_AAD_API_CLIENT_ID}/Admin.Access`
    ]
  }  
};

export const auth0Config = {
  auth0_domain: process.env.REACT_APP_AUTH0_DOMAIN ?? "",
  auth0_client_id: process.env.REACT_APP_AUTH0_CLIENT_ID ?? "",
  redirectUri: `${window.location.origin}`,
  defaultScope: 'openid profile email'
}

export default {
  provider: `${process.env.REACT_APP_AUTH_PROVIDER}`
}