import axiosInstance from "./axios";

export interface RequestSenderObject {
  setLoading: Function,
  url: string,
  method?: "get" | "post" | "put" | "delete",
  cb: (data: any) => void,
  failCb?: (data: any, status?: number | undefined) => void;
  body?: any,
  params?: any
}

export const sendRequest = async (
  token: string, 
  { setLoading,
    url,
    method = "get",
    cb,
    body,
    params,
    failCb,
  }: RequestSenderObject
): Promise<void> => {
  setLoading(true)
  try {
    const { data } = await (method === "delete"
      ? axiosInstance.delete
      : method === "post"
        ? axiosInstance.post
        : method === "put"
          ? axiosInstance.put
          : axiosInstance.get)(url + (params ? Object.keys(params).map(
            (key: any, index: number) =>
              // @ts-ignore
              (index === 0 ? "?" : "&") + String(params[key] as String)).join("") : ""),
            ...(method === "get" ? [] : [body]), 
        {headers: {'Authorization': `${token}`}}
      )
    cb(data)
  } catch (error: any) {
    var data: string = ""
    var status: number | undefined = undefined;

    if (error["response"] != undefined && error["response"]["data"] && error["response"]["data"].length) {
      data = error["response"]["data"][0].message ?? error["response"]["data"];
      status = error['response']['status'];
    } else {
      data = error["message"]
    }
    if (failCb) {
      failCb(data, status)
    }
  }
  finally {
    setLoading(false)
  }
}
