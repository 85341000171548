import { FormViewBuilderField } from "components/FormViewBuilder"
import appMessages from "utils/messages"
import * as Yup from "yup"

export const nieuw_project_fields: FormViewBuilderField[] = [
  {
    type: "text",
    props: { sx: { mt: 1 }, variant: "h6" },
    text: "Project information"
  },
  {
    type: "text",
    props: { sx: { mb: 3 }, variant: "body2" },
    text: "Enter information about the project"
  },
  {
    props: {
      columns: 2,
      perLine: 1
    },
    items: [
      {
        initialValue: "",
        props: {
          label: "Prefix",
          placeholder: "eg: W"
        },
        name: "prefix",
        validation: Yup.string().required(appMessages["required_field"])
      },
      {
        initialValue: "",
        props: {
          label: "Year",
          placeholder: "eg: 2022"
        },
        name: "year",
        validation: Yup.string().required(appMessages["required_field"])
      },
      /* {
        initialValue: "",
        props: {
          label: "Number",
          cols: 2,
          placeholder: "eg: 15"
        },
        name: "number",
        validation: Yup
          .number()
          .typeError(appMessages["this_field_should_be_a_number"])
          .required(appMessages["required_field"])
      } */
    ]
  }]
