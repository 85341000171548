import breakpoints from "theme/breakpoints"

export const APP_VALUES: {
  containerMaxSize: "xl",
  paddingFitContainer: string;
  sideBarWidth: "295px";
  defaultErrorMessage: string;
  formatedErrorMessage: string;
  formatedSuccessfullyCreated: string;
  formatedSuccessfullyUpdated: string;
  formatedSuccessfullyDeleted: string;
  formatedCreateFailed: string;
  formatedUpdateFailed: string;
  formatedDeleteFailed: string;
  defaultNotificationResultError: string;
  sciaXmlModelNotGenerated: string;
  getLastCalculationResultError: string;
  dataNotFound: string;
  dataSuccessfullySaved: string;
  dataUnsuccessfullySaved: string;
  requestSuccessfullySent: string;
} = {
  containerMaxSize: "xl",
  sideBarWidth: "295px",
  paddingFitContainer: `calc((100vw - ${breakpoints.values.xl}px)/2 + 24px)`,
  defaultErrorMessage: 'Er is een fout opgetreden tijdens het laden van gegevens',
  formatedErrorMessage: 'Er is een fout opgetreden tijdens {0} ',
  formatedSuccessfullyCreated: `{0} is met succes toegevoegd.`,
  formatedSuccessfullyUpdated: `{0} is met succes bijgewerkt.`,
  formatedSuccessfullyDeleted: `{0} is met succes verwijderd.`,
  formatedCreateFailed: `Het aanmaken van {0} is mislukt.`,
  formatedUpdateFailed: `Het bijwerken van {0} is mislukt.`,
  formatedDeleteFailed: `Het verwijderen van {0} is mislukt.`,
  defaultNotificationResultError: 'Er is een fout opgetreden tijdens {0} ',
  sciaXmlModelNotGenerated: 'De bestanden xml en xml.def zijn niet gegenereerd.',
  getLastCalculationResultError: 'Er is een fout opgetreden tijdens het ophalen van de resultaten van {0} ',
  dataNotFound: `Geen {0} gevonden.`,
  dataSuccessfullySaved: `Gegevens zijn succesvol opgeslagen.`,
  dataUnsuccessfullySaved: `Er is een fout opgetreden tijdens het opslaan.`,
  requestSuccessfullySent: `De opdracht is met succes verzonden.`
}

export const gevolgklasseCCOptions = [
  {
    label: 1,
    value: 1,
  },
  {
    label: 2,
    value: 2,
  },
  {
    label: 3,
    value: 3,
  },
]

export const betonsterkteklasseOptions = [
  {
    label: "C20/25",
    value: "C20/25"
  },
  {
    label: "C25/30",
    value: "C25/30",
  },
  {
    label: "C30/37",
    value: "C30/37",
  },
  {
    label: "C35/45",
    value: "C35/45",
  },
  {
    label: "C40/50",
    value: "C40/50",
  },
  {
    label: "C45/55",
    value: "C45/55",
  },
  {
    label: "C50/60",
    value: "C50/60",
  },
  {
    label: "C55/67",
    value: "C55/67",
  },
  {
    label: "C60/75",
    value: "C60/75",
  },
]

export const paalTypes = [
  {
    label: "Vierkant",
    value: "vierkant"
  },
  {
    label: "Rond",
    value: "rond"
  }
]

export const schoorstandTypeOptions = [
  {
    label: "Van dek af",
    value: 0
  },
  {
    label: "Richting dek",
    value: 1
  },
  {
    label: "Te lood",
    value: 2
  },
]

export const grondopbouwTypeOptions = [
  {
    label: "Zand",
    value: 0
  },
  {
    label: "Veen",
    value: 1
  },
  {
    label: "Klei",
    value: 2
  }
]

export const booleanTypeOptions = [
  {
    label: "Waar",
    value: true
  },
  {
    label: "Onwaar",
    value: false
  }
]

export const sloofsOptions = [
  {
    label: "Sloof bovenzijde",
    value: 1
  },
  {
    label: "Sloof onderzijde",
    value: 2
  }
]