// highlight
import './utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
import 'react-image-lightbox/style.css';


// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './index.css'

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import * as React from 'react';
import App from './App';
import { Router } from "react-router";
import { createBrowserHistory } from 'history';
import { createRoot } from 'react-dom/client';
import { CollapseDrawerProvider } from "./contexts/CollapseDrawerContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import { HelmetProvider } from "react-helmet-async";


const history = createBrowserHistory();
const container = document.getElementById('root');
// @ts-ignore
const root = createRoot(container);

// makeServer();

root.render(
  <React.Fragment>
    <Router history={history}>
      <HelmetProvider>
        <SettingsProvider>
          <CollapseDrawerProvider>
          {/* <MsalProvider instance={msalInstance}> */}
            <App />
          {/* </MsalProvider> */}
          </CollapseDrawerProvider>
        </SettingsProvider>
      </HelmetProvider>
    </Router>
  </React.Fragment>
);
