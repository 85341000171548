/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { Fragment, lazy, Suspense } from 'react';
import AuthLayout from 'layouts/Auth';
import ApplicationLayout from 'layouts/Application';
import { RouteConfig } from 'react-router-config';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LoadingScreen } from 'components';
import AuthGuard from './AuthGuard';
import GuestGuard from './GuestGuard';
import routes from './routes';
import AuthAdminGuard from './AuthAdminGuard';

const errorPath = "/errors/error-404"

const routesConfig: RouteConfig[] = [
  {
    path: routes.index.index,
    exact: true,
    component: lazy(() => import('routes/Router')),
  },
  {
    path: '/errors/error-404',
    exact: true,
    component: lazy(() => import('views/errors/error.page')),
  },
  {
    path: routes.errors.error_403,
    exact: true,
    component: lazy(() => import('views/errors/error403.page')),
  },
  {
    path: routes.auth.index,
    layout: AuthLayout,
    guard: GuestGuard,
    routes: [
      {
        path: routes.auth.index,
        exact: true,
        component: lazy(() => import('views/auth/welcome/Welcome.page')),
      },
      {
        path: '*',
        component: () => <Redirect to={errorPath} />
      }
    ]
  },
  {
    path: routes.instellingen.index,
    layout: ApplicationLayout,
    guard: AuthAdminGuard,
    routes: [
      {
        path: "",
        exact: true,
        component: lazy(() => import('views/instellingen/index.page')),
      },
      {
        path: '*',
        component: () => <Redirect to={errorPath} />
      }
    ]
  },
  {
    path: routes.mappingConfiguratie.index,
    layout: ApplicationLayout,
    guard: AuthGuard,
    routes: [
      {
        path: '',
        exact: true,
        component: lazy(() => import('views/mappingConfiguratie/index.page')),
      },
      {
        path: '*',
        component: () => <Redirect to={errorPath} />
      }
    ]
  },
  {
    path: routes.gebruikers.index,
    layout: ApplicationLayout,
    guard: AuthAdminGuard,
    routes: [
      {
        path: '',
        exact: true,
        component: lazy(() => import('views/gebruikers/index.page')),
      },
      {
        path: '*',
        component: () => <Redirect to={errorPath} />
      }
    ]
  },
  {
    path: routes.tools.index,
    layout: ApplicationLayout,
    guard: AuthGuard,
    routes: [
      {
        path: routes.tools.loadmodel(),
        exact: true,
        component: lazy(() => import('views/tools/loadmodel/index'))
      },
      {
        path: '',
        exact: true,
        component: lazy(() => import('views/tools/index.page')),
      },
      {
        path: '*',
        component: () => <Redirect to={errorPath} />
      }      
    ]
  },
  {
    path: routes.projecten.index,
    layout: ApplicationLayout,
    guard: AuthGuard,
    routes: [
      {
        path: routes.projecten.all,
        exact: true,
        component: lazy(() => import('views/projecten/all.page')),
      },
      {
        path: routes.projecten.id(),
        exact: true,
        component: lazy(() => import('views/geographicSituation/index.page')),
      },
      {
        path: routes.geographicSituation.toonResult,
        exact: true,
        component: lazy(() => import('views/geographicSituation/toonResult.page')),
      },
      {
        path: '',
        exact: true,
        component: lazy(() => import('views/projecten/index.page')),
      },
      {
        path: '*',
        component: () => <Redirect to={errorPath} />
      }
    ]
  },
  {
    path: routes.notification.index,
    layout: ApplicationLayout,
    guard: AuthGuard,
    routes: [
      {
        path: routes.notification.index,
        exact: true,
        component: lazy(() => import('views/notification/index.page')),
      },
      {
        path: '*',
        component: () => <Redirect to={errorPath} />
      }
    ]
  },
  {
    path: routes.geographicSituation.index,
    layout: ApplicationLayout,
    guard: AuthGuard,
    routes: [
      {
        path: routes.geographicSituation.index,
        exact: true,
        component: lazy(() => import('views/geographicSituation/index.page')),
      },
      {
        path: routes.geographicSituation.toonResult,
        exact: true,
        component: lazy(() => import('views/geographicSituation/toonResult.page')),
      },
      {
        path: '*',
        component: () => <Redirect to={errorPath} />
      }
    ]
  },
  {
    path: routes.profile.index,
    layout: ApplicationLayout,
    guard: AuthGuard,
    routes: [
      {
        path: routes.profile.index,
        exact: true,
        component: lazy(() => import('views/profile/index.page')),
      },
      {
        path: '*',
        component: () => <Redirect to={errorPath} />
      }
    ]
  },

];

const renderRoutes = (routes: RouteConfig[]) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component: any = route.component;
          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
