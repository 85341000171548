import { Loadmodel, Tool, ToolActionRequest, ToolActionTypes } from "store/types";
import { sendRequest } from "utils/request_sender";
import { toolsUrls } from "./urls";

export class ToolsService {

    getTool = async (
        token: string,
        setLoading: Function,
        cb: (data: Tool) => void,
        id?: number,
        code?: string,
        failedCb?: (err: any) => void
    ) => {
        sendRequest(token,
            { 
                setLoading: setLoading,
                url: toolsUrls.GET_TOOL(id, code),
                method: 'get',
                cb: (data: Tool) => {
                    cb(data);
                },
                failCb: (err: any) => {
                    if (failedCb) {
                        failedCb(err);
                    }
                }

            }
        )
    }

    getLoadmodel = async (
        token: string,
        setLoading: Function,
        email: string,
        cb: (data: Loadmodel) => void,
        failedCb: (err: any) => void
    ) => {
        sendRequest(token,
            { 
                setLoading: setLoading,
                url: toolsUrls.GET_TOOL_INPUT(email, "loadmodel"),
                method: 'get',
                cb: (data: Loadmodel) => {
                    cb(data);
                },
                failCb: (err: any) => {
                    if (failedCb) {
                        failedCb(err);
                    }
                }

            }
        )
    }

    updateToolInput = async (
        token: string,
        setLoading: Function,
        code: string,
        data: any,
        cb: (data: any) => void,
        failCb: (data: any) => void
    ) => {
        sendRequest(
            token, {
            setLoading: setLoading,
            url: toolsUrls.UPDATE_TOOL_INPUT(code),
            method: 'put',
            cb: (data: any) => cb(data),
            failCb: (err: any) => {
                if (failCb) {
                    failCb(err);
                }
            },
            body: data
        })
    }

    createToolInput = async (
        token: string,
        setLoading: Function,
        code: string,
        data: any,
        cb: (data: any) => void,
        failCb: (data: any) => void
    ) => {
        sendRequest(
            token, {
            setLoading: setLoading,
            url: toolsUrls.CREATE_TOOL_INPUT(code),
            method: 'post',
            cb: (data: any) => cb(data),
            failCb: (err: any) => {
                if (failCb) {
                    failCb(err);
                }
            },
            body: data
        })
    }

    processAction = async (
        token: string,
        setLoading: Function,
        code: string,
        user: string,
        action: ToolActionTypes,
        cb: (data: any) => void,
        failCb: (err: any, status: any) => void
    ) => {
        var data: ToolActionRequest = { action: action, owner: user, code: code};
        sendRequest(
            token, {
            setLoading: setLoading,
            url: toolsUrls.PROCESS_TOOL_ACTION,
            method: 'post',
            cb: (data: any) => cb(data),
            failCb: (err: any, status: any) => {
                if (failCb) {
                    failCb(err, status);
                }
            },
            body: data
        })
    }

    getToolActionResult = async (
        token: string,
        setLoading: Function,
        email: string,
        code: string,
        cb: (data: Loadmodel) => void,
        failedCb: (err: any) => void
    ) => {
        sendRequest(token,
            { 
                setLoading: setLoading,
                url: toolsUrls.GET_TOOL_ACTION_RESULT(code, email),
                method: 'get',
                cb: (data: Loadmodel) => {
                    cb(data);
                },
                failCb: (err: any) => {
                    if (failedCb) {
                        failedCb(err);
                    }
                }

            }
        )
    }
}