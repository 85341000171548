import { string } from "prop-types"
import { constructionPartsUrls, constructionsUrls, objectItemsUrls, projectUrls } from "services/api/urls"
import { Project } from "store/types/ProjectType"
import { sendRequest } from "utils/request_sender"

export class ProjectService {
    constructor() {
    }

    updateProject = async (
        token: string,
        setLoading: Function,
        data: any,
        user: string,
        isAdmin: boolean,
        cb: (data: any) => void,
        failCb: (err: any) => void 
    ) => {
        sendRequest(
            token, {
                setLoading: setLoading,
                url: projectUrls.UPDATE_PROJECT(user, isAdmin),
                method: 'put',
                body: { ...data },
                cb: (data: any) => {
                    cb(data);
                },
                failCb: (err: any) => {
                    failCb(err);
                }
            }
        )
    }

    createKw = async (
        token: string,
        setLoading: Function,
        data: any,
        cb: (data: any) => void,
        failCb: (err: any) => void
    ) => {
        sendRequest(token, {
            setLoading: setLoading,
            url: constructionsUrls.CREATE_CONSTRUCTION,
            method: "post",
            cb: (data: any) => {
                cb(data);
            },
            body: data,
            failCb: (error) => {
                failCb(error);
            }
        })
    }

    updateKW = async (
        token: string,
        setLoading: Function,
        data: any,
        project_number: string,
        cb: (data: any) => void,
        failCb: (err: any) => void 
    ) => {
        sendRequest(
            token, {
                setLoading: setLoading,
                url: constructionsUrls.UPDATE_CONSTRUCTION,
                method: 'put',
                body: { ...data, project_number: project_number,  },
                cb: (data: any) => {
                    cb(data);
                },
                failCb: (err: any) => {
                    failCb(err);
                }
            }
        )        
    }

    getConstructionParts = async (
        token: string,
        setLoading: Function,
        construction_id: number,
        cb: (data: any) => void,
        failCb: (err: any) => void
    ) => {
        
        sendRequest(token, {
            setLoading: setLoading,
            url: constructionPartsUrls.GET_CONSTRUCTION_PARTS(construction_id),
            method: 'get',
            cb: (data: any) => {
                
                cb(data);
            },
            failCb: (err: any, status: any) => {
                
                failCb(err);
            }
        })
    }
    createConstructionPart = async (
        token: string,
        setLoading: Function,
        payload: any,
        isadmin: boolean,
        cb: (data: any) => void,
        failCb: (err: any) => void
    ) => {
        sendRequest(token, {
            setLoading: setLoading,
            url: constructionPartsUrls.CERATE_CONSTRUCTION_PART,
            method: "post",
            cb: (data: any) => {
              cb(data);
            },failCb:err => {
              failCb(err)
            },
            body: payload
          })
    }

    updateConstructionPart = async (
        token: string,
        setLoading: Function,
        payload: any,
        isadmin: boolean,
        cb: (data: any) => void,
        failCb: (err: any) => void
    ) => {
        sendRequest(token, {
            setLoading: setLoading,
            url: constructionPartsUrls.UPDATE_CONSTRUCTION_PART,
            method: "put",
            cb: (data: any) => {
              cb(data);
            },failCb:err => {
              failCb(err)
            },
            body: payload
          })
    }


    createObject = async (
        token: string,
        setLoading: Function,
        payload: any,
        isadmin: boolean,
        cb: (data: any) => void,
        failCb: (err: any) => void
    ) => {
        sendRequest(token, {
            setLoading: setLoading,
            url: objectItemsUrls.CERATE_OBJECT_ITEM,
            method: "post",
            cb: (data: any) => {
              cb(data);
            },failCb:err => {
              failCb(err)
            },
            body: payload
          })
    }

    updateObject = async (
        token: string,
        setLoading: Function,
        payload: any,
        isadmin: boolean,
        cb: (data: any) => void,
        failCb: (err: any) => void
    ) => {
        sendRequest(token, {
            setLoading: setLoading,
            url: objectItemsUrls.UPDATE_OBJECT_ITEM,
            method: "put",
            cb: (data: any) => {
              cb(data);
            },failCb:err => {
              failCb(err)
            },
            body: payload
          })
    }


}