// @ts-ignore
let host_url = global.location.host;
let host_var = 'DEV';

if (host_url === process.env.REACT_APP_PROD_SERVER) {
  host_var = 'PROD';
} else if (host_url === process.env.REACT_APP_DEV_SERVER) {
  host_var = 'DEV';
}

export default {
  env: host_var,
  api_url: host_url,
  lock_year: process.env.REACT_APP_LOCK_YEAR || undefined
};
