import AppIllustration from 'components/AppIllustration';
import React, { PureComponent } from 'react';
import styles from './ErrorHandler.module.css';

interface ErrorHandlerProps {
  errorElement?: React.ReactNode;
  onError?: Function;
  children?: React.ReactNode
}

interface ErrorHandlerState {
  hasError: boolean;
}

class ErrorHandler extends PureComponent<ErrorHandlerProps, ErrorHandlerState> {
  state = {
    hasError: false
  };

  static getDerivedStateFromError(error: any) {
    // Mettez à jour l'état, de façon à montrer l'UI de repli au prochain rendu.
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true });
    const {
      onError = () => {
      }
    } = this.props;
    onError(error, info);
  }

  render() {
    const {
      errorElement = (
        <AppIllustration
          reload
          src="/static/illustrations/server_down.svg"
          title="Er is een fout opgetreden"
          description="Er is een onverwachte fout opgetreden. Probeer de pagina opnieuw te lagen"
        />
      )
    } = this.props;
    if (this.state.hasError) {
      return errorElement;
    }
    try {
      return <>{this.props.children}</>;
    } catch (error) {
      this.setState({ hasError: true });
      return errorElement;
    }
  }
}

export default ErrorHandler;
