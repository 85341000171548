import React from 'react'
import { Box, Button, ButtonProps, Menu, MenuItem, Stack, Typography } from '@mui/material'
import Iconify from '../Iconify'

export interface AppDropdownButtonItem {
  label?: string;
  action: () => void;
  icon?: any;
}

interface Props {
  items?: AppDropdownButtonItem[];
  icon?: string | any;
  iconSize?: number;
  width?: string | number;
  height?: string | number;
  iconColor?: string;
  disableRipple?: boolean;
  rounded?: boolean;
  color?: string;
}

const AppDropdownButton: React.FC<Props> = ({ items, icon, iconColor, disableRipple, width, rounded, color, height, iconSize }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = (e: any) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Box component={'div'} sx={{
      "& .MuiPaper-root, & .MuiPopover-paper, & ul": {
        backgroundColor: "transparent!important",
        borderRadius: "100px"
      }
    }}>
      {items && (
        <Button
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          sx={{
            backgroundColor: "transparent", width: width ?? "16px", height: height ?? "100%", minHeight: "unset", minWidth: "unset", "&:active": {
              backgroundColor: "transparent"
            }
          }}
          disableRipple={disableRipple}
          onClick={(e) => {
            e.stopPropagation()
            setAnchorEl(e.currentTarget);
          }}
        >
          <Stack alignItems={"center"} sx={{ color: iconColor ?? "grey.600" }}>
            {(typeof icon === "string" || typeof icon === typeof undefined) ? (
              <Iconify icon={icon ?? "gg:more-vertical"} height={iconSize ?? 16} width={iconSize ?? 16} />
            ) : icon}
          </Stack>
        </Button>
      )}
      {items && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
            sx: { backgroundColor: "transparent" }
          }}
        >
          {items?.map((item) => (
            <MenuItem onClick={(e) => {
              handleClose(e)
              item.action()
            }
            }>
              <Stack direction="row" alignItems="center" gap={"14px"}>
                {item.icon}
                <Typography>{item.label}</Typography>
              </Stack>
            </MenuItem>
          ))}
        </Menu>
      )}
    </Box>
  )
}

export default AppDropdownButton
