import { prefixer } from 'services/api/urls'
import {HubConnectionBuilder, HubConnection} from '@microsoft/signalr';
import { utimes } from 'fs';

export const NotificationConnection = (): HubConnection => {
    let url = `${prefixer!.split("/api/v1").shift()}/construcoderhub`
    console.log(url)
    let connection = new HubConnectionBuilder()
        .withUrl(url)
        .withAutomaticReconnect()
        .build();

        connection.start()
            .then(() =>  console.log("Connected: " + connection.connectionId))
            .catch((reason: any) => {
            });
    return connection
}

export const registerEvent = (name: string, callback: (data: any) => void, connection: HubConnection) =>  {
    connection
    .on(name, (result: any) => {
        if (callback) {
            callback(result)
        }
    });
    connection.onclose(error => {
        console.log(error ?? new Error(`Connection [${name}] was closed`));
        console.log(error?.message);
    })
}
