import { LoadingButton } from "@mui/lab"
import { Box, Container, Button, Hidden, Link, Drawer, List, ListItem, ListItemButton, ListItemText, IconButton, Divider, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Iconify } from "components";
import { useState } from "react";
import { NavLink as RouterLink, useHistory, useLocation, useParams } from "react-router-dom";
import routes from "routes/routes";
import useResponsive from "hooks/useResponsive"
import AppDropdownButton from "components/AppDropdownButton";

interface SearchProps {
  title?: string,
  className?: string,
  drawerOpen?: boolean,
  isAdmin?: boolean,
  sub_title_search?: string,
  handleOnClick?: () => void
  handleSearch?: (keyword: string) => void
  closeDrawer: Function,
  user?: any
}

export const SearchComponent = (props: SearchProps) => {

  const { handleOnClick, handleSearch, drawerOpen, closeDrawer, isAdmin } = props

  // Hooks
  const history = useHistory()
  const isMdUp = useResponsive("up", "md")

  // State
  const [keyword, setKeyword] = useState<string>('');

  // Constants
  const inputLightColor = "grey.500"
  const links = [
    // ...(isAdmin ? [{
    //   to: routes.instellingen.index,
    //   label: "Excel berekeningen",
    // }] : []),
    {
      to: routes.projecten.all,
      label: "Projecten"
    },
    {
      to: routes.tools.index,
      label: "Tools"
    },
    // ...(isAdmin ? [{
    //   to: routes.gebruikers.index,
    //   label: "Gebruikers"
    // }] : []),
  ]

  return (
    <Container maxWidth={'xl'} sx={{ display: 'flex', alignItems: 'stretch', pl: "0 !important" }} >
      <Hidden lgDown>
        <Link
          noWrap
          component={RouterLink}
          to={routes.index.index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginRight: "55px",
          }}
        >
          <img height={28} style={{ marginRight: 16 }} src="/construcoder_logo.png" />
          <img height={21} src="/customer_logo.png" />
        </Link>
      </Hidden>
      <Hidden mdDown>
        <Stack direction="row" alignItems={"center"} gap={5}>
          {links.map((link, index) => {
            const isActive = useLocation().pathname.startsWith(link.to)
            return <Link
              key={link.to}
              component={RouterLink}
              to={link.to}
              style={{ fontWeight: isActive ? 700 : undefined, color: !isActive ? "#000" : undefined }}
            >
              {link.label}
            </Link>
          })}

        </Stack>
      </Hidden>
      <Box component={'div'} sx={{
        color: inputLightColor,
        position: 'relative',
        paddingRight: "21px",
        overflow: "hidden",
        height: 32,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: "white",
        flexGrow: 1,
        marginLeft: {
          md: '41px',
          sm: '22px',
          xs: 0
        },
        borderRadius: "5px",
        borderWidth: `1px`,
        borderStyle: "solid",
        borderColor: inputLightColor,
        '& input': {
          height: '100%',
          width: {
            xs: 'calc(100vw - 200px)',
            sm: 'auto'
          },
          flexGrow: 1,
          border: 'none',
          outline: 'none',
          paddingLeft: '18px',
          paddingTop: '12px',
          paddingBottom: '12px',
        },
        '& input::placeholder': {
          color: inputLightColor,
        }
      }}>
        <input
          value={keyword}
          onChange={e => {
            setKeyword(e.target.value)
          }}
          type="text"
          placeholder={props.sub_title_search}
          style={{ width: "100%" }}
        />
        <Iconify icon="carbon:search" height={20} width={20} />
      </Box>
      {/* {isAdmin && <Hidden mdDown>
        <Box sx={{ paddingLeft: 2, display: "flex", alignItems: "center" }}>
          <AppDropdownButton
            width="24px"
            height="24px"
            iconSize={20}
            icon="ant-design:setting-outlined"
            items={links.filter((item, index) => [0, 2].includes(index)).map(item => (
              {
                label: item.label,
                action: () => {
                  history.push(item.to)
                }
              }
            ))}
          />
        </Box>
      </Hidden>} */}
      {props.title && <Button onClick={handleOnClick} sx={{
        minWidth: {
          sm: '164px',
          xs: 'auto'
        },
        px: {
          sm: '6',
          xs: 2
        },
        marginLeft: {
          sm: '22px',
          xs: 1.5
        },
        '&:hover': {
          bgcolor: "#008EAA"
        },
        cursor: 'pointer',
        bgcolor: '#000000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#F8FAFF'
      }}>
        <img src="/static/icons/add.svg" alt="" />
        <Hidden smDown>
          <Typography sx={{ marginLeft: '10px' }}>
            {props.title}
          </Typography>
        </Hidden>
      </Button>}

      <Hidden mdUp>
        <Drawer sx={{ width: '100vw', maxWidth: 360, }} open={drawerOpen} onClose={() => closeDrawer()}>
          <List>
            <ListItem sx={{ mb: "30px", mx: "auto", width: "100%", display: "flex", justifyContent: "center", borderBottom: "1px solid", borderBottomColor: "grey.100", py: 3 }}>
              {/* <img height={50} src="/logo.svg" alt="Construcoder logo" /> */}
              <Link
                noWrap
                component={RouterLink}
                to={routes.index.index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: "55px",
                }}
              >
                  <img height={40} style={{ marginRight: 5 }} src="/customer_logo.png" />
                  <img height={40} src="/construcoder_logo.png" />
              </Link>
            </ListItem>
            {links.map(link => {
              const isActive = useLocation().pathname.startsWith(link.to)
              return (
                <ListItem
                  key={link.to}
                  sx={{
                    backgroundColor: isActive ? "primary.light" : undefined,
                    width: '100vw',
                    maxWidth: 260,
                  }}
                  disablePadding
                >
                  <ListItemButton onClick={() => history.push(link.to)}>
                    <ListItemText primary={link.label} />
                  </ListItemButton>
                </ListItem>
              )
            })}
          </List>
        </Drawer>
      </Hidden>

    </Container>
  )

}
