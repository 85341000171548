import { Box, Button } from "@mui/material";
import AppModal, { AppModalProps } from "components/AppModal"
import FormViewBuilder, { FormViewBuilderField } from "components/FormViewBuilder"
import React from "react"

interface Props extends AppModalProps {
  formRef: any;
  fields: FormViewBuilderField[];
  onSubmit: (values: any) => void;
  renderSubmit?: (formRef: any) => any;
}

const AppModalForm: React.FC<Props> = ({ fields, formRef, onSubmit, renderSubmit, ...othersProps }) => {

  return othersProps.open ? (
    <AppModal closeAtEnd {...othersProps}>
      <Box component={"section"} sx={{ mt: 2 }}>
        <FormViewBuilder
          ref={formRef}
          fields={fields}
          onSubmit={onSubmit}
        />
        {renderSubmit ? (
          renderSubmit(formRef)
        ) : (
          <Button
            sx={{ mt: 4 }}
            onClick={() => {
              formRef.current?.click()
            }} color="primary" variant="contained" fullWidth>
            Opslaan
          </Button>
        )}
      </Box>
    </AppModal>
  ) : null

}

export default AppModalForm
