import { Redirect } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { InteractionStatus } from '@azure/msal-browser';

function GuestGuard(props: any) {
  
  const { children } = props;
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();

  const [isLoading, setLoading] = useState(false)

  useEffect(() => { setLoading(!inProgress || inProgress !== InteractionStatus.None); } , [inProgress]);
  
  console.log(`GuestGuard - isLoading: ${isLoading}, isAuthenticated: ${isAuthenticated}`);
  
  if (isLoading) {
    return <div>...</div>;
  }

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return children;
}

export default GuestGuard;
