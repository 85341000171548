// ----------------------------------------------------------------------

export default function Dialog(theme) {
    return {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    overflow: "hidden",
                    boxShadow: theme.customShadows.dialog,
                    '&.MuiPaper-rounded': {
                        borderRadius: 5,
                    },
                    '&.MuiDialog-paperFullScreen': {
                        borderRadius: 0,
                    },
                    '&.MuiDialog-paper .MuiDialogActions-root': {
                        padding: theme.spacing(3),
                    },
                    '@media (max-width: 600px)': {
                        margin: theme.spacing(2),
                    },
                    '@media (max-width: 663.95px)': {
                        '&.MuiDialog-paperWidthSm.MuiDialog-paperScrollBody': {
                            maxWidth: '100%',
                        },
                    },
                },
                paperFullWidth: {
                    width: '100%',
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    lineHeight: "24px",
                    padding: "29px",
                    paddingTop: "15px",
                    paddingBottom: "14px",
                    color: theme.palette.grey[0],
                    backgroundColor: theme.palette.primary.main,
                    "& button": {
                        height: "36px"
                    },
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    borderTop: 0,
                    padding: "29px",
                    borderBottom: 0,
                    borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5,
                    padding: theme.spacing(3),
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    '& > :not(:first-of-type)': {
                        marginLeft: theme.spacing(1.5),
                    },
                },
            },
        },
    };
}