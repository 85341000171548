import { Redirect } from 'react-router-dom';
import SecureLS from 'secure-ls';
const ls = new SecureLS({ encodingType: 'aes' });
import { useAuth0 } from "@auth0/auth0-react";


function AuthAdminGuard(props: any) {
  const { children } = props;
  // const {isAuthenticated, user, isLoading } = useAuth();

  // if (isLoading) {
  //   return <div>...</div>;
  // }

  // if (!isAuthenticated) {
  //   return <Redirect to="/auth" />;
  // }

  // const roles = user?.["https://construcoder.netlify.app/roles"]  ?? []
  // const isAdmin = isAuthenticated && roles.includes("Admin");

  // if(!isAdmin) return <Redirect to="/" />;

  return children;
}

export default AuthAdminGuard;