import { Grid, Box, Divider, Typography, Hidden, Stack } from '@mui/material';
import AppButtonSpacedIcon from 'components/AppButtonSpacedIcon';
import AppTextField from 'components/AppTextField';
import Iconify from 'components/Iconify';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react'
import * as Yup from "yup"

export type FormViewBuilderField = {
  /**
* Expected values ares:
* @param type: "divider" | "text"
*/
  type?: string;
  text?: string;
  props: { [key: string]: any };
  items?: FormViewBuilderFieldItem[]
}
export type FormViewBuilderFieldItem = { name?: string; parent?: string; initialValue?: any; validation?: any; props?: { [key: string]: any }; }

interface Props {
  ref: any;
  fields: FormViewBuilderField[];
  onSubmit: (values: any) => void;
  initialItem?: any;
  skeleton?: boolean;
  onChanged?: (value: any) => void;
}

const FormViewBuilder: React.FC<Props> = React.forwardRef<HTMLButtonElement, Props>(({ fields, onSubmit, initialItem, skeleton, onChanged}, ref) => {
  const fieldItems: FormViewBuilderFieldItem[] = []
  const formInitialData: any = {}
  const validationSchema: any = {}

  fields.forEach(field => {
    if (field.items) {
      field.items.map(item => {
        fieldItems.push(item)
      })
    }
  }
  )


  fieldItems.forEach((item) => {
    formInitialData[item.name ?? ""] = typeof item.initialValue === "function" ? (item.initialValue(initialItem) ?? "") : (item.initialValue ?? "")
    if (item.validation) {
      validationSchema[item.name ?? ""] = item.validation.nullable()
    }
  })

  // Functions
  const initFormikFieldsObj = {
    initialValues: formInitialData,
    validationSchema: Yup.object(validationSchema),
  }

  // Formik
  const formik = useFormik({
    ...initFormikFieldsObj,
    enableReinitialize: true,
    onSubmit: onSubmit
  })

  const buildField = (field: FormViewBuilderField) => {
    if (Array.isArray(field.items)) {
      if (field.type == 'boolean-check') {
        return (<Grid container columnSpacing={"20px"} rowSpacing={"3px"} {...field.props}>
        {field.items.map((item, index) => {
          const value = formik.values[item.name ?? ""];
          const label = (item.props || {}).label;
          const success = (value as string).toLocaleLowerCase() === 'true';
          return(
            <Grid key={`${item.props?.label ?? ""}-${index}`} item xs={field.props.columns ? field.props.columns : 12} sm={field.props.columns ? field.props.perLine : 6} lg={field.props.columns ? undefined : 4} {...(item.props?.cols || {})}>           
              <Stack direction={"row"} my={.8} gap={.1} alignItems={'center'}>
                <Typography  variant='body2' color={!success ? "error" : "success.main"} sx={{ mb: "1px" }}>
                  {label} 
                </Typography>
                <Typography  variant='body2' color={!success ? "error" : "success"} sx={{ mb: "2px" }}>
                  <Iconify sx={{ color: success ? 'success.main' : 'error.main' }} icon={success ? 'ant-design:check-circle-outlined' : 'ant-design:close-circle-outlined'}  height={20} width={20} />
                </Typography>
              </Stack>
            </Grid>
          )
        })}
        </Grid>)
      } else {
        return (
          <Grid container columnSpacing={"20px"} rowSpacing={"22px"} {...field.props}>
            {field.items.map((item, index) => {
              const isInvalid = !!(formik.touched[item.name ?? ""] && formik.errors[item.name ?? ""])
              const errorMessage = formik.errors[item.name ?? ""] as any;
              
              return (
                <Grid key={`${item.props?.label ?? ""}-${index}`} item xs={field.props.columns ? field.props.columns : 12} sm={field.props.columns ? field.props.perLine : 6} lg={field.props.columns ? undefined : 4} {...(item.props?.cols || {})}>
                  {!skeleton ? (<AppTextField
                    {...item.props}
                    name={item.name}
                    error={isInvalid}
                    value={formik.values[item.name ?? ""]}
                    onValueChange={(value) => {
                      let data = { name: item.name, value };
                      formik.handleChange({ target: data })
                      if (onChanged) {
                        onChanged({ item: item, data: data})
                      }
                    }}
                    onBlur={formik.handleBlur}
                  />) : (
                    <Box component="section" {...item.props} className="skeleton-loading" sx={{ borderRadius: "5px", height: 4, ...(item.props?.sx || {}) }} />
                  )}
                  {isInvalid && (
                    <Typography color="error" variant="caption">{errorMessage}</Typography>
                  )}
                </Grid>
              )
            })}
          </Grid>)
      }

    } else {
      return (
        (field.type === "divider")
          ? (
            skeleton ? <Box component={'section'} {...field.props} className="skeleton-loading" sx={{ borderRadius: "5px", height: 4, ...(field.props.sx || {}) }} /> : <Divider {...field.props} />
          )
          : (!!field.text)
            ? (
              skeleton
                ? <Box component="section" {...field.props} className="skeleton-loading" sx={{ borderRadius: "5px", height: 4, ...(field.props.sx || {}) }} />
                : <Typography {...field.props}>{field.text}</Typography>
            )
            : null)
    }
  }
  const buildForm = (fieldsData: FormViewBuilderField[] = fields) => fieldsData.map(field => buildField(field))

  return (
    <Box component={'section'}>
      {buildForm()}
      <button
        ref={ref}
        style={{ display: "none" }}
        type="submit"
        onClick={() => {
          formik.handleSubmit()
        }}
      />
    </Box>
  )
})

export default FormViewBuilder
