import { sendRequest } from "utils/request_sender";
import { reportsUrls } from "./urls";

export class ReportService {

    getRebarInfo = async (
     token: string,
     setLoading: Function,
     objectId: number,
     user: string,
     cb: (data: any) => void,
     failCb: (err: any) => void
     ) => {
        sendRequest(token, {
            setLoading: setLoading,
            url: reportsUrls.GET_REBAR_INFO(objectId, user),
            method: "get",
            cb: (data: any) => {
                cb(data);
            },
            failCb: (error, status) => {
                failCb({ error: error, status: status})
            }
        })
    }

    createRebar = async (
        token: string,
        setLoading: Function,
        objectId: number,
        payload: any,
        cb: (data: any) => void,
        failCb: (error: any ) => void
    ) => {
        sendRequest(token, {
            setLoading: setLoading,
            url: reportsUrls.CREATE_REBAR_DRAWING(objectId),
            method: 'post',
            cb: (data: any) => {
                cb(data);
            },
            failCb: (error, status) => {
                failCb({ error: error, status: status })
            },
            body: payload
        });
    }

    createReport = async (
        token: string,
        setLoading: Function,
        objectId: number,
        user: string,
        payload: any,
        cb: (data: any) => void,
        failCb: (error: any ) => void
    ) => {
        sendRequest(token, {
            setLoading: setLoading,
            url: reportsUrls.CREATE_REPORT(objectId, user),
            method: 'post',
            body: payload,
            cb: (data: any) => {
                cb(data);
            },
            failCb: (error, status) => {
                failCb({ error: error, status: status });
            }
        })
    }
}