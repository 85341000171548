const routes = {
  index: {
    index: "/"
  },
  auth: {
    index: "/auth"
  },
  instellingen: {
    index: "/instellingen"
  },
  projecten: {
    index: "/projecten",
    all: "/projecten/all",
    id: (id: string | number = ":id") => `/projecten/${id}`
  },
  tools: {
    index: "/tools",
    loadmodel: (code: string = ":code") => `/tools/${code}`
  },
  gebruikers: {
    index: "/gebruikers",
  },
  mappingConfiguratie: {
    index: "/mapping-configuratie"
  },
  geographicSituation: {
    index: "/geographic-situation",
    toonResult: "/geographic-situation/toon-result",
  },
  profile: {
    index: "/profile"
  },
  notification: {
    index: "/notifications"
  },
  errors: {
    error_404: "/errors/error-404",
    error_403: "/errors/error-403"
  }
}

export default routes
