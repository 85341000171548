import { Box, Dialog, DialogContent, DialogTitle, Stack } from '@mui/material'
import React from 'react'
import useResponsive from "hooks/useResponsive"

export type AppModalHandleClose = ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void)

export interface AppModalProps {
  handleClose: AppModalHandleClose;
  size?: "xl" | "md" | "sm";
  title?: string;
  outlined?: boolean;
  sm?: boolean;
  children?: any;
  contentPrimary?: boolean;
  stacked?: "left" | "right";
  actions?: any;
  maxWidth?: string;
  closeAtEnd?: boolean;
  open?: boolean;
  color?: string;
}

const AppModal: React.FC<AppModalProps> = ({ handleClose, title, outlined, sm, children, stacked, actions, contentPrimary, maxWidth, closeAtEnd, open, color }) => {

  // Hooks
  const isUpSm = useResponsive("up", "md")

  // Components
  const CloseBtn = () => (
    <button onClick={(e) => { if (handleClose instanceof Function) handleClose({}, "backdropClick") }} style={{ height: stacked ? 18 : 24, width: stacked ? 18 : 24, backgroundColor: "transparent", border: "none", display: "flex", justifyContent: "start", padding: 0, marginLeft: "-8px", cursor: "pointer" }}>
      <Box component='section' color="white">
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M9 9L17 17" stroke="white" stroke-width="0.5" stroke-linecap="round" />
          <path d="M17 9L9 17" stroke="white" stroke-width="0.5" stroke-linecap="round" />
        </svg>
      </Box>
    </button>
  )

  return (
    <Dialog
      open={typeof open !== typeof undefined ? !!open : true}
      sx={{
        borderRadius: stacked ? "0 !important" : undefined,
        backgroundColor: "transparent",
        maxWidth: maxWidth
          ? maxWidth
          : sm
            ? "482px"
            : stacked
              ? isUpSm
                ? "90vw"
                : "100vw"
              : undefined,
        mx: stacked ? undefined : "auto",
        mr: stacked === "left" ? "auto" : undefined,
        ml: stacked === "right" ? "auto" : undefined,
        "& .MuiPaper-root": {
          margin: "0 !important",
          maxWidth: maxWidth || undefined
        }
      }}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      fullWidth
      fullScreen={!!stacked}
    >
      <DialogTitle sx={{ backgroundColor: color ?? "primary.main" }}>
        <Stack direction={"row"}>
          <Stack direction={"row"} spacing={"13px"} alignItems={"center"}>
            {!closeAtEnd && <CloseBtn />}
            <span style={{ whiteSpace: "nowrap" }}>
              {title}
            </span>
          </Stack>
          <Box component='section' flexGrow={1} />
          {actions}
          {closeAtEnd && <CloseBtn />}
        </Stack>

      </DialogTitle>
      <DialogContent sx={{ ...(contentPrimary ? { backgroundColor: "primary.main", borderTop: "2px solid #0005" } : {}) }}>
        {children}
      </DialogContent>
    </Dialog>
  )
}

export default React.memo<React.FC<AppModalProps>>(AppModal)
