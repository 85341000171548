import React from 'react';
import { Provider } from 'react-redux';
import { jssPreset, StylesProvider, } from "@mui/styles";
import { create } from 'jss';
// import rtl from 'jss-rtl';
import CssBaseline from '@mui/material/CssBaseline';
import store from 'store';
import Routes from 'routes';

import 'translations';
import Auth from 'routes/Auth';
import { StyledEngineProvider } from "@mui/styled-engine-sc";
import { PaletteMode } from "@mui/material";
import SecureLS from 'secure-ls';


import NotistackProvider from './components/NotistackProvider';
import { MotionLazyContainer } from "./components/animate";
import ScrollToTop from "./components/ScrollToTop";
import { ProgressBarStyle } from "./components/ProgressBar";
import { ChartStyle } from "./components/chart";
import ThemeProvider from "./theme";
import { HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from "react-query/devtools";
import { NotificationConnection } from 'services/notifications/notificationService';
import { useHistory } from 'react-router';
import authConfig from './authConfig'

import ConstruCoderAuthProvider from 'contexts/AuthContext';
import { ToolsService } from 'services/api/ToolsService';
import { ProjectService } from 'services/api/ProjectService';
import { ParameterService } from 'services/api/ParameterServices';
import { CalculationService } from 'services/api/CalculationService';
import { TestingService } from 'services/api/TestingService';
import { ReportService } from 'services/api/ReportService';

export const ls = new SecureLS({ encodingType: 'aes' });
export const notificationService = NotificationConnection();
export const toolsService = new ToolsService();
export const projectService = new ProjectService();
export const parameterService = new ParameterService();
export const calculationService = new CalculationService();
export const testingService = new TestingService();
export const reportService = new ReportService();

const jss = create({ plugins: [...jssPreset().plugins,] });

export const ColorModeContext = React.createContext({
  toggleColorMode: () => {
  }
});

// const msalInstance = new PublicClientApplication(msalConfig.publicApp);
const App = () => {

  let initialMode = ls.get('palette_mode') || 'light';

  const [mode, setMode] = React.useState<PaletteMode>(initialMode);
  const history = useHistory();
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = (prevMode === 'light' ? 'dark' : 'light');
          ls.set('palette_mode', newMode);
          return newMode;
        });
      },
    }),
    [],
  );

  const helmetContext = {};
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retryDelay: 10 * 1000
      },
      mutations: {
        retry: false
      }
    }
  });


  return (
  <React.Fragment>
    {/* <Auth0Provider
        domain={config.auth0_domain}
        clientId={config.auth0_client_id}
        redirectUri={window.location.origin}
        onRedirectCallback={(appState) => {
          console.log({appState})
          history.push(appState?.returnTo || window.location.pathname);
        }}
        advancedOptions={{
          defaultScope: 'openid profile email'
        }}
      > */}
    {/* <MsalProvider instance={msalInstance} > */}
    <ConstruCoderAuthProvider provider={authConfig.provider}>
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <Provider store={store}>
            <HelmetProvider context={helmetContext}>
              <ColorModeContext.Provider value={colorMode}>
                <MotionLazyContainer>
                  <NotistackProvider>
                    <ThemeProvider>
                      <StylesProvider jss={jss}>
                        <React.Fragment>
                          <CssBaseline />
                          <ProgressBarStyle />
                          <ChartStyle />
                          <ScrollToTop />
                          <Auth>
                            {/*<ScrollReset />*/}
                            <Routes />
                            {/*{config.env === 'DEV' && <MockedButton/>}*/}
                          </Auth>
                        </React.Fragment>
                      </StylesProvider>
                    </ThemeProvider>
                  </NotistackProvider>
                </MotionLazyContainer>
              </ColorModeContext.Provider>
            </HelmetProvider>
          </Provider>
        </StyledEngineProvider>
      <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    {/* </Auth0Provider> */}
    {/* </MsalProvider> */}
    </ConstruCoderAuthProvider>
  </React.Fragment>

  );
};

export default App;
