import React, {useState} from 'react';
import {makeStyles} from '@mui/styles';
import moment from "moment";
import 'moment/locale/fr';
import AdapterDateFns from '@mui/lab/AdapterMoment';
import {useTranslation} from 'react-i18next';
import {Box, StandardTextFieldProps, TextField, Theme, useMediaQuery, useTheme} from '@mui/material';
import {SvgIconProps} from "@mui/material/SvgIcon";
import {DatePicker, DateTimePicker, LocalizationProvider, TimePicker} from "@mui/lab";


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        // marginTop: theme.spacing(2),
        "& svg": {
            fill: '#fff'
        }
    },
}));

export interface InputTextProps extends StandardTextFieldProps {
    labelPrefix?: string;
    labelSuffix?: string;
    clearContent?: () => void;
    startIcon?: React.ReactElement<SvgIconProps>;
    endIcon?: React.ReactElement<SvgIconProps>;
    onInputOverflowClick?: () => void;
}

export interface InputDateComponentProps extends InputTextProps {
    views?: any[];
    timeViews?: ('hours' | 'minutes' | 'seconds')[];
    dateMomentFormat: string;
    minDate?: Date;
    maxDate?: Date;
    inputColor?: string
    format?: string;
    onDateChange: (date?: Date) => void;
}

export default function (props: InputDateComponentProps) {
    const classes = useStyles();
    const {i18n, t} = useTranslation();
    const [open, setOpen] = useState<boolean>(false);
    const {label, required, inputColor: color} = props;
    const {
        views,
        onDateChange,
        timeViews,
        minDate,
        value,
        dateMomentFormat,
        startIcon,
        type,
        maxDate,
        format,
        endIcon,
        clearContent,
        ...inputProps
    } = props;
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));
    moment.locale(i18n.language);

    return (
        <div className={classes.root}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={i18n.language}>
                {
                    type === 'date' &&
                    <DatePicker
                        label={label}
                        value={value || null}
                        minDate={minDate}
                        maxDate={maxDate}
                        onChange={(date: any) => {
                            onDateChange(date._d);
                        }}
                        renderInput={(params) =>
                            <TextField {...params}
                                       fullWidth
                                // InputProps={{
                                //     ...params.InputProps,
                                //     endAdornment: <InputAdornment position={'end'}>
                                //         <Box color={'text.primary'}>
                                //             <Calendar/>
                                //         </Box>
                                //     </InputAdornment>
                                // }}
                                       helperText={props.helperText}
                                       error={props.error}/>}
                    />
                }
                {
                    type === 'time' &&
                    <TimePicker
                        label={label}
                        value={value || null}
                        onChange={(date: any) => {
                            onDateChange(date._d);
                        }}
                        renderInput={(params) =>
                            <TextField {...params}
                                       fullWidth
                                // InputProps={{
                                //     ...params.InputProps,
                                //     endAdornment: <InputAdornment position={'end'}>
                                //         <Box color={'text.primary'}>
                                //             <Clock/>
                                //         </Box>
                                //     </InputAdornment>
                                // }}
                                       helperText={props.helperText}
                                       error={props.error}/>}
                    />
                }
                {
                    type === 'date_time' &&
                    <Box component={'div'} sx={{
                        '& label': {
                            fontSize: 14,
                            transform: 'translate(14px,12px) scale(1)',
                            color: color !== 'dark' ? '#ffffff' : '#B8B8B8',
                            // opacity: 1
                        },
                        '& input': {
                            padding: '12px 19px',
                            color: color !== 'dark' ? '#ffffff' : 'text.primary',
                            fontSize: 14
                        },
                        '& .MuiSvgIcon-root': {
                            fill: theme =>  color !== 'dark' ? '#ffffff' : theme.palette.primary.main,
                        },
                        '& legend span': {
                            fontSize: 11
                        },
                        '& label.Mui-focused, label.MuiFormLabel-filled': {
                            transform: 'translate(16px, -8px) scale(.75)'
                        },
                        '& label.Mui-focused': {
                            color: color !== 'dark' ? '#ffffff' : 'primary.main',
                        },
                        '& label.MuiFormLabel-filled': {
                            // opacity:  color !== 'dark' ? .5 : 1,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor:color !== 'dark' ?  '#FFFFFF!important' : '#B8B8B8',
                        },
                        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderWidth: '2px!important',
                            borderColor: color !== 'dark' ? '#FFFFFF' : 'primary.main!important',
                        },
                    }}>
                        <DateTimePicker
                            label={label}
                            value={value || null}
                            minDate={minDate}
                            maxDate={maxDate}
                            disabled={props.disabled}
                            className={'random-class'}
                            onChange={(date: any) => {
                                if (date)
                                    onDateChange(date._d);
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    error={props.error}
                                    fullWidth
                                    // InputProps={{
                                    //     ...params.InputProps,
                                    //     endAdornment: <InputAdornment position={'end'}>
                                    //         <Box color={'text.primary'}>
                                    //             <Calendar/>
                                    //         </Box>
                                    //     </InputAdornment>
                                    // }}
                                    helperText={props.helperText}
                                />}
                        />
                    </Box>

                }
            </LocalizationProvider>
        </div>
    );
}
