import { Box, MenuItem, Select, Typography } from '@mui/material'
import Iconify from 'components/Iconify';
import React from 'react'

export interface AppSelectItem {
  label: string | number;
  value: string | number;
}

interface Props {
  label?: string;
  items: AppSelectItem[];
  error?: boolean;
  value?: any;
  onValueChange?: (value: any) => void;
}

const AppSelect: React.FC<Props> = ({ label, items, onValueChange, ...otherProps }) => {
  return (
    <Box component={'div'}>
      <Typography variant='body2' color={otherProps.error ? "error" : "grey.500"} sx={{ mb: "5px" }}>
        {label}
      </Typography>
      <Select
        sx={{ padding: "12px 18px", width: "100%" }}
        IconComponent={() => <Box component={'div'} color="grey.500" sx={{ pr: "14px" }}><Iconify icon="dashicons:arrow-down" /></Box>}
        {...otherProps}
        onChange={(e) => {
          if (e.target.value !== null)
            if (onValueChange) onValueChange(e.target.value)
        }}
      >
        {items.map((item, index) => (
          <MenuItem key={String(item.value) + index} value={item.value}>{item.label}</MenuItem>
        ))}
      </Select>
    </Box>
  )
}

export default AppSelect
